<template>
  <div>
    <v-fade-transition mode="out-in">
      <notifications group="foo" position="top right" />
    </v-fade-transition>
    <v-fade-transition mode="out-in">
      <notifications group="auth" position="bottom left" />
    </v-fade-transition>
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
  </div>
</template>

<script>
// Styles
import '@/styles/overrides.sass';

export default {
  name: 'App',
  metaInfo: {
    title: 'Datakalan S.A.',
    titleTemplate: 'Datakalan S.A.',
    htmlAttrs: { lang: 'en' },
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    ],
  },
};
</script>
<style lang="scss">
.vue-notification {
  border-radius: 15px;
  margin-top: 10px !important;
  padding: 10px;
  margin: 0 5px 5px;

  font-size: 18px !important;

  color: #ffffff;
  background: #44a3fc9c;
  border-left: 5px solid #1880e733;
  width: 100% !important;

  &.warn {
    background: #ffb648ad;
    border-left-color: #f4890662;
  }

  &.error {
    background: #e54d42a8 !important;
    border-left-color: #b82e2460;
  }

  &.success {
    background: #68cd86af;
    border-left-color: #42a85f49;
  }

  &.notify {
    background: #b0c5ceaf;
    border-left-color: #74bdbd49;
  }
}
.vue-notification > .notification-title {
  margin-bottom: 15px;
}
</style>
