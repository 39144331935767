<template>
  <div>
    <canvas id="graficaPorMunicipio"></canvas>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import departamentos from './../../components/departamentos.js';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
  name: 'graficaMunicipios',
  props: {
    prop_departamento: {
      type: String,
    },
    prop_fecha: {
      type: String,
    },
  },
  data() {
    return {
      departamentos: departamentos,
      // información de la grafica
      dataGraficaBarrasMunicipios: {
        type: 'bar',
        data: {
          labels: [],
          datasets: [
            {
              label: 'Sin asignar',
              data: [],
              totalData: [],
              backgroundColor: '#00cae3',
              borderColor: '#00cae3',
              borderWidth: 1,
            },
            {
              label: 'En ruta',
              data: [],
              totalData: [],
              backgroundColor: '#4a148c',
              borderColor: '#4a148c',
              borderWidth: 1,
            },
            {
              label: 'Entregados',
              data: [],
              totalData: [],
              backgroundColor: '#4caf50',
              borderColor: '#4caf50',
              borderWidth: 1,
            },
            {
              label: 'Devolución',
              data: [],
              totalData: [],
              backgroundColor: '#ff9800',
              borderColor: '#ff9800',
              borderWidth: 1,
            },
            {
              label: 'Anulados',
              data: [],
              totalData: [],
              backgroundColor: '#F44336',
              borderColor: '#F44336',
              borderWidth: 1,
            },
          ],
        },
        options: {
          indexAxis: 'y',
          scales: {
            y: {
              beginAtZero: true,
              stacked: true,
            },
            x: {
              stacked: true,
              /**
               * chartjs editar TICKS
               */
              ticks: {
                // Include a dollar sign in the ticks
                callback: function (value, index, values) {
                  return value + '%';
                },
              },
            },
          },
          plugins: {
            /**value
             * chartjs editar las etiquetas || editar los tooltips
             */
            tooltip: {
              callbacks: {
                label: function (context) {
                  var label = context.dataset.label || '';

                  if (label) {
                    label +=
                      ': ' +
                      context.dataset.totalData[context.parsed.y] +
                      ' (' +
                      context.parsed.x +
                      '%)';
                  }

                  return label;
                },
              },
            },
          },
        },
      },
    };
  },
  methods: {
    ...mapActions('DashboardResumenPorMunicipio', {
      findResumenPorMunicipio: 'find',
    }),
    main() {
      this.fnRenderizarGraficaDeBarras();
    },
    fnRenderizarGraficaDeBarras() {
      /**
       * mandar a llamar la información al servidor
       * params: departamento
       * params: fecha (formato YYYY-MM)
       */
      this.findResumenPorMunicipio({
        query: {
          departamento: this.prop_departamento,
          fecha: this.prop_fecha,
        },
      }).then((result) => {
        console.log('guias agrupadas por municipio', result);
        result.forEach((muni) => {
          // etiquetas
          this.dataGraficaBarrasMunicipios.data.labels.push(
            muni._id + ' (' + muni.totalGuias + ')'
          );
          // porcentajes
          this.dataGraficaBarrasMunicipios.data.datasets[0].data.push(
            ((muni.sinAsignar / muni.totalGuias) * 100).toFixed(2)
          );
          this.dataGraficaBarrasMunicipios.data.datasets[1].data.push(
            ((muni.asignados / muni.totalGuias) * 100).toFixed(2)
          );
          this.dataGraficaBarrasMunicipios.data.datasets[2].data.push(
            ((muni.entregados / muni.totalGuias) * 100).toFixed(2)
          );
          this.dataGraficaBarrasMunicipios.data.datasets[3].data.push(
            ((muni.devueltos / muni.totalGuias) * 100).toFixed(2)
          );
          this.dataGraficaBarrasMunicipios.data.datasets[4].data.push(
            ((muni.anulados / muni.totalGuias) * 100).toFixed(2)
          );
          // totales
          this.dataGraficaBarrasMunicipios.data.datasets[0].totalData.push(
            muni.sinAsignar
          );
          this.dataGraficaBarrasMunicipios.data.datasets[1].totalData.push(
            muni.asignados
          );
          this.dataGraficaBarrasMunicipios.data.datasets[2].totalData.push(
            muni.entregados
          );
          this.dataGraficaBarrasMunicipios.data.datasets[3].totalData.push(
            muni.devueltos
          );
          this.dataGraficaBarrasMunicipios.data.datasets[4].totalData.push(
            muni.anulados
          );
        });
        // init
        var ctx = document
          .getElementById('graficaPorMunicipio')
          .getContext('2d');
        new Chart(ctx, this.dataGraficaBarrasMunicipios);
      });

      /**
       * verción antigua, consulta y generación de información
       */
      /**
       * consulta de archivo .js
       */
      let departamento = this.departamentos.filter(
        (dpto) => dpto.departamento == this.prop_departamento
      );
      /*************************** */
      // números
    },
    random_rgba() {
      let color = { fill: '', outline: '' };

      let o = Math.round;
      let r = Math.random;
      let s = 255;

      color.fill =
        'rgba(' +
        o(r() * s) +
        ',' +
        o(r() * s) +
        ',' +
        o(r() * s) +
        ',' +
        0.2 +
        ')';
      /** */

      color.outline =
        'rgba(' +
        o(r() * s) +
        ',' +
        o(r() * s) +
        ',' +
        o(r() * s) +
        ',' +
        1 +
        ')';

      return color;
    },
  },
  mounted() {
    this.main();
  },
  computed: {},
};
</script>
<style scoped>
#graficaPorMunicipio {
  min-height: 100%;
}
</style>
