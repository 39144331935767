import { render, staticRenderFns } from "./dptoGraficas.vue?vue&type=template&id=18c8e564&scoped=true&"
import script from "./dptoGraficas.vue?vue&type=script&lang=js&"
export * from "./dptoGraficas.vue?vue&type=script&lang=js&"
import style0 from "./dptoGraficas.vue?vue&type=style&index=0&id=18c8e564&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18c8e564",
  null
  
)

export default component.exports