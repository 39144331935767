/* eslint-disable */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import './plugins';
import store from './store';
import { sync } from 'vuex-router-sync';

import Notifications from 'vue-notification';
import * as VueGoogleMaps from 'vue2-google-maps';
window.moment = require('moment');
require('moment/locale/es');
Vue.use(Notifications);
Vue.config.productionTip = false;
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places', // This is required if you use the Autocomplete plugin
    installComponents: true,
  },
});
import ZoomOnHover from 'vue-zoom-on-hover';
Vue.use(ZoomOnHover);
var filter = function (text, length, clamp) {
  clamp = clamp || '...';
  var node = document.createElement('div');
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter('truncate', filter);
sync(store, router);
window.$ = require('jquery');
new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app');
