import store from './../store';
import { layout, route } from '@/util/routes';

/** */
async function isLoggedIn(to, from, next) {
  // store.dispatch("auth/logout").then(result => console.log("logout 2"));
  store;
  // just in case need to log out
  // .dispatch("auth/logout")
  // .then(res => {
  //   console.log(res);
  //   next("/login");
  // })
  // .catch(() => {
  //   console.log("ready to go Otu!");
  //   next("/login");
  // });
  try {
    const logged = await store.dispatch('auth/authenticate');

    if (logged && logged.gpsusuario.rol != 3) {
      console.log('logged', logged);
      next();
    } else {
      await this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  } catch (e) {
    if (e.code && e.code === 403) {
      next('/casa');
    } else {
      next('/login');
    }
  } finally {
    store.commit('auth/unsetAuthenticatePending');
  }
}
/** */
const routes = [
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  layout('Default', [
    {
      path: '/',
      name: 'Inicio',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "DASHBOARD" */ '../views/Dashboard.vue'),
      beforeEnter: isLoggedIn,
    },
    {
      path: '/test',
      name: 'test',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "TEST" */ '../views/test.vue'),
      beforeEnter: isLoggedIn,
    },
    {
      path: 'Dashboard',
      name: 'Panel',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "DASHBOARD" */ '../views/Dashboard.vue'),
      beforeEnter: isLoggedIn,
    },
    // Pages
    {
      path: '/components/profile/',
      name: 'Editar perfil de usuario',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "LOGIN" */ '../views/UserProfile.vue'),
      beforeEnter: isLoggedIn,
    },

    {
      path: '/Guias/',
      name: 'Guias',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "LOGIN" */ '../views/gestiondeguias/guias.vue'
        ),
      beforeEnter: isLoggedIn,
    },

    {
      path: '/departamentos/',
      name: 'Totales por departamento',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "LOGIN" */ '../views/vistasresumenes/departamentos.vue'
        ),
      beforeEnter: isLoggedIn,
    },
    {
      path: '/departamentos/:departamento/:fecha',
      name: 'Detalle por departamento',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "LOGIN" */ '../views/vistasresumenes/departamentosDetalle.vue'
        ),
      beforeEnter: isLoggedIn,
    },
    {
      path: '/pilotos/:placa/:piloto/:fecha',
      name: '',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "LOGIN" */ '../views/vistasresumenes/pilotosDetalle.vue'
        ),
      beforeEnter: isLoggedIn,
    },
    {
      path: '/pilotos/',
      name: 'Resumen por pilotos',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "LOGIN" */ '../views/vistasresumenes/pilotos.vue'
        ),
      beforeEnter: isLoggedIn,
    },
    {
      path: '/manifiestos/',
      name: 'Manifiestos del mes',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "LOGIN" */ '../views/manifiestos/manifiestos.vue'
        ),
      beforeEnter: isLoggedIn,
    },
    {
      path: '/mapa/',
      name: 'Resumen del día',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "LOGIN" */ '../views/vistasresumenes/mapa.vue'
        ),
      beforeEnter: isLoggedIn,
    },
    {
      path: '/gps/',
      name: 'Resumen ETR',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "LOGIN" */ '../views/vistasresumenes/mapa_gps.vue'
        ),
      beforeEnter: isLoggedIn,
    },
    // Components
    {
      path: 'components/notifications',
      name: 'Notifications',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "NOTIFICATIONS" */ '../views/Notifications.vue'
        ),
      beforeEnter: isLoggedIn,
    },
    {
      path: 'components/icons',
      name: 'Icons',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "ICONS" */ '../views/Icons.vue'),
      beforeEnter: isLoggedIn,
    },
    {
      path: 'components/typography',
      name: 'Typography',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "TYPO" */ '../views/Typography.vue'),
      beforeEnter: isLoggedIn,
    },

    // Tables
    {
      path: 'tables/regular',
      name: 'Regular Tables',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "REGULARTABLES" */ '../views/RegularTables.vue'
        ),
      beforeEnter: isLoggedIn,
    },

    // Maps
    {
      path: 'maps/google',
      name: 'Google Maps',
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "GOOGLEMAPS" */ '../views/GoogleMaps.vue'),
      beforeEnter: isLoggedIn,
    },
    // Gestión del plan
    {
      path: '/perfil/',
      name: 'Perfil de usuario',
      component: () =>
        import(/* webpackChunkName: "perfil" */ '../views/perfil/perfil.vue'),
      beforeEnter: isLoggedIn,
    },
  ]),
];

export default routes;
