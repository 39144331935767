<template>
  <div>
    <canvas id="graficaPiloto"></canvas>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
  name: 'graficaPiloto',
  props: ['prop_placa', 'prop_piloto', 'prop_fecha'],
  data() {
    return {
      // información de la grafica
      dataPilotoGrafica: {
        type: 'pie',
        data: {
          labels: [],
          datasets: [],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Resumen de guías',
            },
          },
        },
      },
    };
  },
  methods: {
    ...mapActions('DashboardResumenPorEstado', {
      findResumenPorEstado: 'find',
    }),
    random_rgba() {
      let color = { fill: '', outline: '' };

      let o = Math.round;
      let r = Math.random;
      let s = 255;

      color.fill =
        'rgba(' +
        o(r() * s) +
        ',' +
        o(r() * s) +
        ',' +
        o(r() * s) +
        ',' +
        0.2 +
        ')';
      /** */

      color.outline =
        'rgba(' +
        o(r() * s) +
        ',' +
        o(r() * s) +
        ',' +
        o(r() * s) +
        ',' +
        1 +
        ')';

      return color.fill;
    },
    fnRenderizado() {
      this.findResumenPorEstado({
        query: {
          fecha: this.prop_fecha,
          prop_placa: this.prop_placa,
          prop_piloto: this.prop_piloto,
        },
      }).then((result) => {
        //* CARGAR DATOS GRAFICA INFORMACION DE LA GRAFICA
        let guias2 = [
          {
            data: [],
            backgroundColor: [],
          },
        ];

        // PUSH DATATASETS
        result.forEach((grupoGuias) => {
          /**
           * get store data set
           */
          let store_estado = this.$store.state.estados.filter(
            (store_estado) => store_estado.db_label == grupoGuias._id[0]
          );
          // AGREGAR ETIQUETA
          this.dataPilotoGrafica.data.labels.push(store_estado[0].title);

          // AGREGAR NÚMERO
          guias2[0].data.push(grupoGuias.total);
          // AGREGAR COLOR

          console.log('color: ' + store_estado[0].color);
          guias2[0].backgroundColor.push(store_estado[0].hexacolor);
        });

        /**
         * RENDERIZAR GRÁFICA
         */

        this.dataPilotoGrafica.data.datasets = guias2;
        var ctx = document.getElementById('graficaPiloto').getContext('2d');
        new Chart(ctx, this.dataPilotoGrafica);
      });
    },
  },
  mounted() {
    this.fnRenderizado();
  },
};
</script>
<style scoped>
#graficaPiloto {
  min-height: 100%;
}
</style>
