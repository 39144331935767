export const departamentos = [
  {
    departamento: 'Alta Verapaz',
    municipios: [
      { nombre: 'Cahabón', guias: 3 },
      { nombre: 'Chahal', guias: 3 },
      { nombre: 'Chisec', guias: 3 },
      { nombre: 'Cobán', guias: 3 },
      { nombre: 'Fray Bartolomé de las Casas', guias: 3 },
      { nombre: 'Lanquín', guias: 3 },
      { nombre: 'Panzós', guias: 3 },
      { nombre: 'Raxruha', guias: 3 },
      { nombre: 'San Cristóbal Verapaz', guias: 3 },
      { nombre: 'San Juan Chamelco', guias: 3 },
      { nombre: 'San Pedro Carchá', guias: 3 },
      { nombre: 'Santa Cruz Verapaz', guias: 3 },
      { nombre: 'Senahú', guias: 3 },
      { nombre: 'Tactic', guias: 3 },
      { nombre: 'Tamahú', guias: 3 },
      { nombre: 'Tucurú', guias: 3 },
      { nombre: 'Santa Catarina La Tinta', guias: 3 },
    ],
  },
  {
    departamento: 'Baja Verapaz',
    municipios: [
      { nombre: 'Cubulco', guias: 3 },
      { nombre: 'Granados', guias: 3 },
      { nombre: 'Purulhá', guias: 3 },
      { nombre: 'Rabinal', guias: 3 },
      { nombre: 'Salamá', guias: 3 },
      { nombre: 'San Jerónimo', guias: 3 },
      { nombre: 'San Miguel Chicaj', guias: 3 },
      { nombre: 'Santa Cruz El Chol', guias: 3 },
    ],
  },
  {
    departamento: 'Chimaltenango',
    municipios: [
      { nombre: 'Acatenango', guias: 3 },
      { nombre: 'Chimaltenango', guias: 3 },
      { nombre: 'El Tejar', guias: 3 },
      { nombre: 'Parramos', guias: 3 },
      { nombre: 'Patzicía', guias: 3 },
      { nombre: 'Patzún', guias: 3 },
      { nombre: 'Pochuta', guias: 3 },
      { nombre: 'San Andrés Itzapa', guias: 3 },
      { nombre: 'San José Poaquil', guias: 3 },
      { nombre: 'San Juan Comalapa', guias: 3 },
      { nombre: 'San Martín Jilotepeque', guias: 3 },
      { nombre: 'Santa Apolonia', guias: 3 },
      { nombre: 'Santa Cruz Balanyá', guias: 3 },
      { nombre: 'Tecpán Guatemala', guias: 3 },
      { nombre: 'Yepocapa', guias: 3 },
      { nombre: 'Zaragoza', guias: 3 },
    ],
  },
  {
    departamento: 'Chiquimula',
    municipios: [
      { nombre: 'Camotán', guias: 3 },
      { nombre: 'Chiquimula', guias: 3 },
      { nombre: 'Concepción Las Minas', guias: 3 },
      { nombre: 'Esquipulas', guias: 3 },
      { nombre: 'Ipala', guias: 3 },
      { nombre: 'Jocotán', guias: 3 },
      { nombre: 'Olopa', guias: 3 },
      { nombre: 'Quezaltepeque', guias: 3 },
      { nombre: 'San Jacinto', guias: 3 },
      { nombre: 'San José La Arada', guias: 3 },
      { nombre: 'San Juan Ermita', guias: 3 },
    ],
  },
  {
    departamento: 'El Progreso',
    municipios: [
      { nombre: 'El Jícaro', guias: 3 },
      { nombre: 'Guastatoya', guias: 3 },
      { nombre: 'Morazán', guias: 3 },
      { nombre: 'San Agustín Acasaguastlán', guias: 3 },
      { nombre: 'San Antonio La Paz', guias: 3 },
      { nombre: 'San Cristóbal Acasaguastlán', guias: 3 },
      { nombre: 'Sanarate', guias: 3 },
    ],
  },
  {
    departamento: 'Escuintla',
    municipios: [
      { nombre: 'Escuintla', guias: 3 },
      { nombre: 'Guanagazapa', guias: 3 },
      { nombre: 'Iztapa', guias: 3 },
      { nombre: 'La Democracia', guias: 3 },
      { nombre: 'La Gomera', guias: 3 },
      { nombre: 'Masagua', guias: 3 },
      { nombre: 'Nueva Concepción', guias: 3 },
      { nombre: 'Palín', guias: 3 },
      { nombre: 'San José', guias: 3 },
      { nombre: 'San Vicente Pacaya', guias: 3 },
      { nombre: 'Santa Lucía Cotzumalguapa', guias: 3 },
      { nombre: 'Siquinalá', guias: 3 },
      { nombre: 'Tiquisate', guias: 3 },
    ],
  },
  {
    departamento: 'Guatemala',
    municipios: [
      { nombre: 'Amatitlán', guias: 3 },
      { nombre: 'Chinautla', guias: 3 },
      { nombre: 'Chuarrancho', guias: 3 },
      { nombre: 'Fraijanes', guias: 3 },
      { nombre: 'Guatemala', guias: 3 },
      { nombre: 'Mixco', guias: 3 },
      { nombre: 'Palencia', guias: 3 },
      { nombre: 'Petapa', guias: 3 },
      { nombre: 'San José del Golfo', guias: 3 },
      { nombre: 'San José Pinula', guias: 3 },
      { nombre: 'San Juan Sacatepéquez', guias: 3 },
      { nombre: 'San Pedro Ayampuc', guias: 3 },
      { nombre: 'San Pedro Sacatepéquez', guias: 3 },
      { nombre: 'San Raymundo', guias: 3 },
      { nombre: 'Santa Catarina Pinula', guias: 3 },
      { nombre: 'Villa Canales', guias: 3 },
      { nombre: 'Villa Nueva', guias: 3 },
    ],
  },
  {
    departamento: 'Huehuetenango',
    municipios: [
      { nombre: 'Aguacatán', guias: 3 },
      { nombre: 'Chiantla', guias: 3 },
      { nombre: 'Colotenango', guias: 3 },
      { nombre: 'Concepción Huista', guias: 3 },
      { nombre: 'Cuilco', guias: 3 },
      { nombre: 'Huehuetenango', guias: 3 },
      { nombre: 'Ixtahuacán', guias: 3 },
      { nombre: 'Jacaltenango', guias: 3 },
      { nombre: 'La Democracia', guias: 3 },
      { nombre: 'La Libertad', guias: 3 },
      { nombre: 'Malacatancito', guias: 3 },
      { nombre: 'Nentón', guias: 3 },
      { nombre: 'San Antonio Huista', guias: 3 },
      { nombre: 'San Gaspar Ixchil', guias: 3 },
      { nombre: 'San Juan Atitán', guias: 3 },
      { nombre: 'San Juan Ixcoy', guias: 3 },
      { nombre: 'San Mateo Ixtatán', guias: 3 },
      { nombre: 'San Miguel Acatán', guias: 3 },
      { nombre: 'San Pedro Necta', guias: 3 },
      { nombre: 'San Rafael La Independencia', guias: 3 },
      { nombre: 'San Rafael Petzal', guias: 3 },
      { nombre: 'San Sebastián Coatán', guias: 3 },
      { nombre: 'San Sebastián Huehuetenango', guias: 3 },
      { nombre: 'Santa Ana Huista', guias: 3 },
      { nombre: 'Santa Bárbara', guias: 3 },
      { nombre: 'Santa Cruz Barillas', guias: 3 },
      { nombre: 'Santa Eulalia', guias: 3 },
      { nombre: 'Santiago Chimaltenango', guias: 3 },
      { nombre: 'Soloma', guias: 3 },
      { nombre: 'Tectitán', guias: 3 },
      { nombre: 'Todos Santos Cuchumatan', guias: 3 },
    ],
  },
  {
    departamento: 'Izabal',
    municipios: [
      { nombre: 'El Estor', guias: 3 },
      { nombre: 'Livingston', guias: 3 },
      { nombre: 'Los Amates', guias: 3 },
      { nombre: 'Morales', guias: 3 },
      { nombre: 'Puerto Barrios', guias: 3 },
    ],
  },
  {
    departamento: 'Jutiapa',
    municipios: [
      { nombre: 'Agua Blanca', guias: 3 },
      { nombre: 'Asunción Mita', guias: 3 },
      { nombre: 'Atescatempa', guias: 3 },
      { nombre: 'Comapa', guias: 3 },
      { nombre: 'Conguaco', guias: 3 },
      { nombre: 'El Adelanto', guias: 3 },
      { nombre: 'El Progreso', guias: 3 },
      { nombre: 'Jalpatagua', guias: 3 },
      { nombre: 'Jerez', guias: 3 },
      { nombre: 'Jutiapa', guias: 3 },
      { nombre: 'Moyuta', guias: 3 },
      { nombre: 'Pasaco', guias: 3 },
      { nombre: 'Quezada', guias: 3 },
      { nombre: 'San José Acatempa', guias: 3 },
      { nombre: 'Santa Catarina Mita', guias: 3 },
      { nombre: 'Yupiltepeque', guias: 3 },
      { nombre: 'Zapotitlán', guias: 3 },
    ],
  },
  {
    departamento: 'Petén',
    municipios: [
      { nombre: 'Dolores', guias: 3 },
      { nombre: 'Flores', guias: 3 },
      { nombre: 'La Libertad', guias: 3 },
      { nombre: 'Melchor de Mencos', guias: 3 },
      { nombre: 'Poptún', guias: 3 },
      { nombre: 'San Andrés', guias: 3 },
      { nombre: 'San Benito', guias: 3 },
      { nombre: 'San Francisco', guias: 3 },
      { nombre: 'San José', guias: 3 },
      { nombre: 'San Luis', guias: 3 },
      { nombre: 'Santa Ana', guias: 3 },
      { nombre: 'Sayaxché', guias: 3 },
      { nombre: 'Las Cruces', guias: 3 },
    ],
  },
  {
    departamento: 'Quetzaltenango',
    municipios: [
      { nombre: 'Almolonga', guias: 3 },
      { nombre: 'Cabricán', guias: 3 },
      { nombre: 'Cajolá', guias: 3 },
      { nombre: 'Cantel', guias: 3 },
      { nombre: 'Coatepeque', guias: 3 },
      { nombre: 'Colomba', guias: 3 },
      { nombre: 'Concepción Chiquirichapa', guias: 3 },
      { nombre: 'El Palmar', guias: 3 },
      { nombre: 'Flores Costa Cuca', guias: 3 },
      { nombre: 'Génova', guias: 3 },
      { nombre: 'Huitán', guias: 3 },
      { nombre: 'La Esperanza', guias: 3 },
      { nombre: 'Olintepeque', guias: 3 },
      { nombre: 'Ostuncalco', guias: 3 },
      { nombre: 'Palestina de Los Altos', guias: 3 },
      { nombre: 'Quetzaltenango', guias: 3 },
      { nombre: 'Salcajá', guias: 3 },
      { nombre: 'San Carlos Sija', guias: 3 },
      { nombre: 'San Francisco La Unión', guias: 3 },
      { nombre: 'San Martín Sacatepéquez', guias: 3 },
      { nombre: 'San Mateo', guias: 3 },
      { nombre: 'San Miguel Sigüilá', guias: 3 },
      { nombre: 'Sibilia', guias: 3 },
      { nombre: 'Zunil', guias: 3 },
    ],
  },
  {
    departamento: 'Quiché',
    municipios: [
      { nombre: 'Canillá', guias: 3 },
      { nombre: 'Chajul', guias: 3 },
      { nombre: 'Chicamán', guias: 3 },
      { nombre: 'Chiché', guias: 3 },
      { nombre: 'Chichicastenango', guias: 3 },
      { nombre: 'Chinique', guias: 3 },
      { nombre: 'Cunén', guias: 3 },
      { nombre: 'Ixcán', guias: 3 },
      { nombre: 'Joyabaj', guias: 3 },
      { nombre: 'Nebaj', guias: 3 },
      { nombre: 'Pachalum', guias: 3 },
      { nombre: 'Patzité', guias: 3 },
      { nombre: 'Sacapulas', guias: 3 },
      { nombre: 'San Andrés Sajcabajá', guias: 3 },
      { nombre: 'San Antonio Ilotenango', guias: 3 },
      { nombre: 'San Bartolomé Jocotenango', guias: 3 },
      { nombre: 'San Juan Cotzal', guias: 3 },
      { nombre: 'San Pedro Jocopilas', guias: 3 },
      { nombre: 'Santa Cruz del Quiché', guias: 3 },
      { nombre: 'Uspantán', guias: 3 },
      { nombre: 'Zacualpa', guias: 3 },
    ],
  },
  {
    departamento: 'Retalhuleu',
    municipios: [
      { nombre: 'Champerico', guias: 3 },
      { nombre: 'El Asintal', guias: 3 },
      { nombre: 'Nuevo San Carlos', guias: 3 },
      { nombre: 'Retalhuleu', guias: 3 },
      { nombre: 'San Andrés Villa Seca', guias: 3 },
      { nombre: 'San Felipe', guias: 3 },
      { nombre: 'San Martín Zapotitlán', guias: 3 },
      { nombre: 'San Sebastián', guias: 3 },
      { nombre: 'Santa Cruz Muluá', guias: 3 },
    ],
  },
  {
    departamento: 'Sacatepéquez',
    municipios: [
      { nombre: 'Alotenango', guias: 3 },
      { nombre: 'Antigua', guias: 3 },
      { nombre: 'Ciudad Vieja', guias: 3 },
      { nombre: 'Jocotenango', guias: 3 },
      { nombre: 'Magdalena Milpas Altas', guias: 3 },
      { nombre: 'Pastores', guias: 3 },
      { nombre: 'San Antonio Aguas Calientes', guias: 3 },
      { nombre: 'San Bartolomé Milpas Altas', guias: 3 },
      { nombre: 'San Lucas Sacatepéquez', guias: 3 },
      { nombre: 'San Miguel Dueñas', guias: 3 },
      { nombre: 'Santa Catarina Barahona', guias: 3 },
      { nombre: 'Santa Lucía Milpas Altas', guias: 3 },
      { nombre: 'Santa María de Jesús', guias: 3 },
      { nombre: 'Santiago Sacatepéquez', guias: 3 },
      { nombre: 'Santo Domingo Xenacoj', guias: 3 },
      { nombre: 'Sumpango', guias: 3 },
    ],
  },
  {
    departamento: 'San Marcos',
    municipios: [
      { nombre: 'Ayutla', guias: 3 },
      { nombre: 'Catarina', guias: 3 },
      { nombre: 'Comitancillo', guias: 3 },
      { nombre: 'Concepción Tutuapa', guias: 3 },
      { nombre: 'El Quetzal', guias: 3 },
      { nombre: 'El Rodeo', guias: 3 },
      { nombre: 'El Tumbador', guias: 3 },
      { nombre: 'Esquipulas Palo Gordo', guias: 3 },
      { nombre: 'Ixchiguan', guias: 3 },
      { nombre: 'La Reforma', guias: 3 },
      { nombre: 'Malacatán', guias: 3 },
      { nombre: 'Nuevo Progreso', guias: 3 },
      { nombre: 'Ocos', guias: 3 },
      { nombre: 'Pajapita', guias: 3 },
      { nombre: 'Río Blanco', guias: 3 },
      { nombre: 'San Antonio Sacatepéquez', guias: 3 },
      { nombre: 'San Cristóbal Cucho', guias: 3 },
      { nombre: 'San José Ojetenam', guias: 3 },
      { nombre: 'San Lorenzo', guias: 3 },
      { nombre: 'San Marcos', guias: 3 },
      { nombre: 'San Miguel Ixtahuacán', guias: 3 },
      { nombre: 'San Pablo', guias: 3 },
      { nombre: 'San Pedro Sacatepéquez', guias: 3 },
      { nombre: 'San Rafael Pie de La Cuesta', guias: 3 },
      { nombre: 'San Sibinal', guias: 3 },
      { nombre: 'Sipacapa', guias: 3 },
      { nombre: 'Tacaná', guias: 3 },
      { nombre: 'Tajumulco', guias: 3 },
      { nombre: 'Tejutla', guias: 3 },
    ],
  },
  {
    departamento: 'Jalapa',
    municipios: [
      { nombre: 'Jalapa', guias: 3 },
      { nombre: 'Mataquescuintla', guias: 3 },
      { nombre: 'Monjas', guias: 3 },
      { nombre: 'San Carlos Alzatate', guias: 3 },
      { nombre: 'San Luis Jilotepeque', guias: 3 },
      { nombre: 'San Pedro Pinula', guias: 3 },
      { nombre: 'San Manuel Chaparrón', guias: 3 },
    ],
  },
  {
    departamento: 'Santa Rosa',
    municipios: [
      { nombre: 'Barberena', guias: 3 },
      { nombre: 'Casillas', guias: 3 },
      { nombre: 'Chiquimulilla', guias: 3 },
      { nombre: 'Cuilapa', guias: 3 },
      { nombre: 'Guazacapán', guias: 3 },
      { nombre: 'Nueva Santa Rosa', guias: 3 },
      { nombre: 'Oratorio', guias: 3 },
      { nombre: 'Pueblo Nuevo Viñas', guias: 3 },
      { nombre: 'San Juan Tecuaco', guias: 3 },
      { nombre: 'San Rafael Las Flores', guias: 3 },
      { nombre: 'Santa Cruz Naranjo', guias: 3 },
      { nombre: 'Santa María Ixhuatán', guias: 3 },
      { nombre: 'Santa Rosa de Lima', guias: 3 },
      { nombre: 'Taxisco', guias: 3 },
    ],
  },
  {
    departamento: 'Sololá',
    municipios: [
      { nombre: 'Concepción', guias: 3 },
      { nombre: 'Nahualá', guias: 3 },
      { nombre: 'Panajachel', guias: 3 },
      { nombre: 'San Andrés Semetabaj', guias: 3 },
      { nombre: 'San Antonio Palopó', guias: 3 },
      { nombre: 'San José Chacaya', guias: 3 },
      { nombre: 'San Juan La Laguna', guias: 3 },
      { nombre: 'San Lucas Tolimán', guias: 3 },
      { nombre: 'San Marcos La Laguna', guias: 3 },
      { nombre: 'San Pablo La Laguna', guias: 3 },
      { nombre: 'San Pedro La Laguna', guias: 3 },
      { nombre: 'Santa Catarina Ixtahuacan', guias: 3 },
      { nombre: 'Santa Catarina Palopó', guias: 3 },
      { nombre: 'Santa Clara La Laguna', guias: 3 },
      { nombre: 'Santa Cruz La Laguna', guias: 3 },
      { nombre: 'Santa Lucía Utatlán', guias: 3 },
      { nombre: 'Santa María Visitación', guias: 3 },
      { nombre: 'Santiago Atitlán', guias: 3 },
      { nombre: 'Sololá', guias: 3 },
    ],
  },
  {
    departamento: 'Suchitepéquez',
    municipios: [
      { nombre: 'Chicacao', guias: 3 },
      { nombre: 'Cuyotenango', guias: 3 },
      { nombre: 'Mazatenango', guias: 3 },
      { nombre: 'Patulul', guias: 3 },
      { nombre: 'Pueblo Nuevo', guias: 3 },
      { nombre: 'Río Bravo', guias: 3 },
      { nombre: 'Samayac', guias: 3 },
      { nombre: 'San Antonio Suchitepéquez', guias: 3 },
      { nombre: 'San Bernardino', guias: 3 },
      { nombre: 'San Francisco Zapotitlán', guias: 3 },
      { nombre: 'San Gabriel', guias: 3 },
      { nombre: 'San José El Idolo', guias: 3 },
      { nombre: 'San Juan Bautista', guias: 3 },
      { nombre: 'San Lorenzo', guias: 3 },
      { nombre: 'San Miguel Panán', guias: 3 },
      { nombre: 'San Pablo Jocopilas', guias: 3 },
      { nombre: 'Santa Bárbara', guias: 3 },
      { nombre: 'Santo Domingo Suchitepequez', guias: 3 },
      { nombre: 'Santo Tomas La Unión', guias: 3 },
      { nombre: 'Zunilito', guias: 3 },
    ],
  },
  {
    departamento: 'Totonicapán',
    municipios: [
      { nombre: 'Momostenango', guias: 3 },
      { nombre: 'San Andrés Xecul', guias: 3 },
      { nombre: 'San Bartolo', guias: 3 },
      { nombre: 'San Cristóbal Totonicapán', guias: 3 },
      { nombre: 'San Francisco El Alto', guias: 3 },
      { nombre: 'Santa Lucía La Reforma', guias: 3 },
      { nombre: 'Santa María Chiquimula', guias: 3 },
      { nombre: 'Totonicapán', guias: 3 },
    ],
  },
  {
    departamento: 'Zacapa',
    municipios: [
      { nombre: 'Cabañas', guias: 3 },
      { nombre: 'Estanzuela', guias: 3 },
      { nombre: 'Gualán', guias: 3 },
      { nombre: 'Huité', guias: 3 },
      { nombre: 'La Unión', guias: 3 },
      { nombre: 'Río Hondo', guias: 3 },
      { nombre: 'San Diego', guias: 3 },
      { nombre: 'Teculután', guias: 3 },
      { nombre: 'Usumatlán', guias: 3 },
      { nombre: 'Zacapa', guias: 3 },
    ],
  },
];

export default departamentos;
