<template>
  <v-card class="" elevation="3" max-width="">
    <v-card-text>
      <v-dialog
        ref="dialog"
        v-model="modalFecha"
        :return-value.sync="$store.state.globalMonth"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="formatDate"
            label="Mostrar información del mes:"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <!-- /*** DASHBOARD SELECT FECHA DATERANGE MES */ -->
        <v-date-picker
          v-model="$store.state.globalMonth"
          type="month"
          locale="es"
        >
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="modalFecha = false"> Cancelar </v-btn>
          <v-btn
            text
            color="green"
            @click="$refs.dialog.save($store.state.globalMonth)"
          >
            Mostrar
          </v-btn>
        </v-date-picker>
        <!-- /*** DASHBOARD SELECT FECHA DATERANGE MES */ -->
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'GlobalMonth',
  data() {
    return {
      /** monthPicker data begin */

      modalFecha: false,
      /** monthPicker data fin */
    };
  },
  computed: {
    formatDate() {
      return this.$store.state.globalMonth
        ? moment(this.$store.state.globalMonth).format('MMMM-YYYY')
        : '';
    },
    /** */
  },
};
</script>
