// Pathify
import { make } from 'vuex-pathify';

// Data
const state = {
  drawer: null,
  drawerImage: true,
  mini: false,
  // opciones de menu principal
  // sidebar opciones .js
  itemsMenuPrincipal: [
    {
      title: 'Resumen',
      icon: 'mdi-view-dashboard',
      to: '/',
      divider: true,
    },
    {
      title: 'Por departamento',
      icon: 'mdi-map-search',
      to: '/departamentos',
    },
    {
      title: 'Por piloto',
      icon: 'mdi-truck',
      to: '/pilotos',
      divider: true,
    },

    {
      title: 'Guías',
      icon: 'mdi-inbox-arrow-up',
      to: '/guias',
    },
    {
      title: 'Manifiestos',
      icon: 'mdi-inbox-arrow-up',
      to: '/manifiestos',
      divider: true,
    },
    {
      title: 'Entregas del día (ETR)',
      icon: 'mdi-map-marker-distance',
      to: '/mapa',
    },
    // {
    //   title: 'GPS',
    //   icon: 'mdi-map-marker',
    //   to: '/gps',
    // },
    // {
    //   title: 'Entregas del día ETR',
    //   icon: 'mdi-map-marker',
    //   to: '/maps/google/',
    // },
  ],
  items: [
    {
      title: 'Resumen',
      icon: 'mdi-view-dashboard',
      to: '/',
    },
    {
      title: 'Crear guía',
      icon: 'mdi-account',
      to: '/components/profile/',
    },
    {
      title: 'Google Maps',
      icon: 'mdi-map-marker',
      to: '/maps/google/',
    },
    {
      title: 'Regular Tables',
      icon: 'mdi-clipboard-outline',
      to: '/tables/regular/',
    },
    {
      title: 'Typography',
      icon: 'mdi-format-font',
      to: '/components/typography/',
    },
    {
      title: 'Icons',
      icon: 'mdi-chart-bubble',
      to: '/components/icons/',
    },
    {
      title: 'Google Maps',
      icon: 'mdi-map-marker',
      to: '/maps/google/',
    },
    {
      title: 'Notifications',
      icon: 'mdi-bell',
      to: '/components/notifications/',
    },
  ],
};

const mutations = make.mutations(state);

const actions = {
  ...make.actions(state),
  init: async ({ dispatch }) => {
    //
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
