// src/store/index.js
/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';
import { FeathersVuex } from '../feathers-client';
import auth from './store.auth';
import pathify from '@/plugins/vuex-pathify';

Vue.use(Vuex);
Vue.use(FeathersVuex);

// Modules
import * as modules from './modules';

const requireModule = require.context(
  // The path where the service modules live
  './services',
  // Whether to look in subfolders
  false,
  // Only include .js files (prevents duplicate imports`)
  /.js$/
);
const servicePlugins = requireModule
  .keys()
  .map((modulePath) => requireModule(modulePath).default);

const store = new Vuex.Store({
  state: {
    roles: [
      {
        rol_id: 0,
        rol_descripcion: 'Super Usuario',
        restricciones: 'No tiene restricciones y puede crear nuevos usuarios.',
      },
      {
        rol_id: 1,
        rol_descripcion: 'Administrativo',
        restricciones:
          'Tiene acceso a la visualizacion de datos del panel, no puede crear nuevos usuarios.',
      },
      {
        rol_id: 3,
        rol_descripcion: 'Piloto',
        restricciones:
          'Este solo tiene acceso a la aplicación móvil para carga y entrega de guías.',
      },
    ],
    estados: [
      // {
      //   actionIcon: 'mdi-bus-marker',
      //   actionText: 'Guias en recolección',
      //   color: '#FD9A13',
      //   hexacolor: '#FD9A13',
      //   icon: 'mdi-bus-marker',
      //   title: 'En recolección',
      //   value: '0',
      //   db_label: 'En recolección'
      // },

      {
        actionIcon: 'mdi-home-map-marker',
        actionText: 'Guias en espera a ser asignadas',
        color: 'info',
        hexacolor: '#00cae3',
        icon: 'mdi-office-building-marker',
        title: 'Sin asignación',
        value: '0',
        db_label: 'Pendiente de asignación',
      },
      {
        actionIcon: 'mdi-map-marker-right-outline',
        actionText: 'Guias asignadas a vehiculo',
        color: 'purple',
        hexacolor: '#4a148c',
        icon: 'mdi-map-marker-distance',
        title: 'Asignadas, en ruta',
        value: '0',
        db_label: 'Asignado a vehículo',
      },
      {
        actionIcon: 'mdi-map-marker-check-outline',
        actionText: 'Guias ya entregados',
        color: 'success',
        hexacolor: '#4caf50',
        icon: 'mdi-map-marker-check',
        title: 'Entregadas',
        value: '0',
        db_label: 'Entregado',
      },
      {
        actionIcon: 'mdi-keyboard-return',
        actionText: 'Guias con devolución',
        color: 'orange',
        hexacolor: '#ff9800',
        icon: 'mdi-phone-return',
        title: 'Devolución',
        value: '0',
        db_label: 'Con devolución',
      },
      {
        actionIcon: 'mdi-null',
        actionText: 'Guias anuladas',
        color: 'red',
        hexacolor: '#F44336',
        icon: 'mdi-null',
        title: 'Anulados',
        value: '0',
        db_label: 'Anulado',
      },
    ],
    paginado: {
      pageName: 'N/A',
      pageNumber: 1,
    },
    globalMonth: new Date().toISOString().substr(0, 7),
  },
  mutations: {},
  actions: {},
  modules,
  plugins: [...servicePlugins, auth, pathify.plugin],
});

store.subscribe((mutation) => {
  if (!mutation.type.startsWith('user/')) return;

  store.dispatch('user/update', mutation);
});

store.dispatch('app/init');

export default store;

export const ROOT_DISPATCH = Object.freeze({ root: true });
